import React, { memo } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import { NavLink } from 'react-router-dom';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DashboardIcon from '@material-ui/icons/Dashboard';

const useStyles = makeStyles((theme: Theme) => ({
    listItem: {
        borderTopRightRadius: theme.spacing(4),
        borderBottomRightRadius: theme.spacing(4),
        backgroundColor: "inherit",
        '&:hover': {
            backgroundColor: "inherit"
        }
    },
    nestedListItemLink: {
        paddingLeft: theme.spacing(7)
    },
    activeMenu: {
        backgroundColor: theme.palette.action.selected,
        '&:hover': {
            backgroundColor: theme.palette.action.selected
        }
    }
}))

type ListItemLinkProps = {
    icon: JSX.Element | null
    text: string
    to: string
    onClick: () => void
    listItemClassName?: string
}

type NavigationMenuProps = {
    closeDrawer?: () => void
}

const ListItemLink = memo((props: ListItemLinkProps) => {
    const { icon, text, to } = props;
    const classes = useStyles()

    return (
        <NavLink onClick={props.onClick} to={to} activeClassName={classes.activeMenu}>
            <ListItem button className={`${props.listItemClassName} ${classes.listItem}`}>
                {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                <ListItemText primary={text} />
            </ListItem>
        </NavLink>
    );
})

const NavigationMenu = (props: NavigationMenuProps) => {
    const classes = useStyles()
    const [openedNestedMenuIndex, setOpenedNestedMenuIndex] = React.useState(-1);
    const onMenuClick = () => {
        if (openedNestedMenuIndex !== -1) {
            setOpenedNestedMenuIndex(-1)
        }
        if (props.closeDrawer) {
            props.closeDrawer()
        }
    }
    const onNestedMenuClick = () => {
        if (props.closeDrawer) {
            props.closeDrawer()
        }
    }
    const onNestedMenuTogglerClick = (index: number) => {
        if (openedNestedMenuIndex === index) {
            setOpenedNestedMenuIndex(-1)
        } else {
            setOpenedNestedMenuIndex(index)
        }
    }

    return (
        <List>
            <ListItemLink onClick={onMenuClick} to="/journals" text={"Journals"} icon={<DashboardIcon />} />
        </List>
    )
}

export default memo(NavigationMenu)