import {Journal} from "../../components/Journal/Journal.model";

export const FETCH_JOURNAL = 'FETCH_JOURNAL'
export const EDIT_JOURNAL = 'EDIT_JOURNAL'
export const ADD_JOURNAL = 'ADD_JOURNAL'
export const DELETE_JOURNAL = 'DELETE_JOURNAL'

interface FetchJournalAction {
    type: typeof FETCH_JOURNAL
    error?: string
    payload: Journal[]
}

interface EditJournalAction {
    type: typeof EDIT_JOURNAL
    error?: string
    payload: Journal
}

interface AddJournalAction {
    type: typeof ADD_JOURNAL
    error?: string
    payload: Journal
}

interface RemoveJournalAction {
    type: typeof DELETE_JOURNAL
    error?: string
    payload: {
        id: string
    }
}

export type JournalActionTypes = FetchJournalAction | EditJournalAction | AddJournalAction | RemoveJournalAction