import {Redirect, Route, RouteProps} from 'react-router';
import React from 'react';
import {isLoggedIn} from "../../../utils/userUtils";

interface Props extends RouteProps {
    restrictedForSignedInUser?: boolean
}

class PublicRoute extends Route<Props> {
    render() {
        if (this.props.restrictedForSignedInUser && isLoggedIn()) {
            return <Redirect to={'/journals'}/>
        }

        return <Route {...this.props}/>
    }
}

export default PublicRoute