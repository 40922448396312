import {User} from "../../components/Profile/User";

export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'

export interface UserState {
    isLoggedIn: boolean
    token: string | null
    profile: User
}

export interface LoginResponsePayload {
    token: string
    user: User
}

interface LoginWithGoogleAction {
    type: typeof LOGIN,
    payload: LoginResponsePayload
    error?: string
}

interface LogoutFromServerAction {
    type: typeof LOGOUT
    error?: string
}

export type UserActionTypes = LoginWithGoogleAction | LogoutFromServerAction