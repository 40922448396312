import { JournalEntry } from "../../components/JournalEntry/JornalEntry.model";

export const FETCH_JOURNAL_ENTRY = 'FETCH_JOURNAL_ENTRY'
export const EDIT_JOURNAL_ENTRY = 'EDIT_JOURNAL_ENTRY'
export const ADD_JOURNAL_ENTRY = 'ADD_JOURNAL_ENTRY'
export const DELETE_JOURNAL_ENTRY = 'DELETE_JOURNAL_ENTRY'

interface FetchJournalEntryAction {
    type: typeof FETCH_JOURNAL_ENTRY
    error?: string
    payload: JournalEntry[]
}

interface EditJournalEntryAction {
    type: typeof EDIT_JOURNAL_ENTRY
    error?: string
    payload: JournalEntry
}

interface AddJournalEntryAction {
    type: typeof ADD_JOURNAL_ENTRY
    error?: string
    payload: JournalEntry
}

interface RemoveJournalEntryAction {
    type: typeof DELETE_JOURNAL_ENTRY
    error?: string
    payload: {
        id: string
    }
}

export type JournalEntryActionTypes = FetchJournalEntryAction | EditJournalEntryAction | AddJournalEntryAction | RemoveJournalEntryAction