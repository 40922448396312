import _ from "lodash";
import { Journal } from "../../components/Journal/Journal.model";
import { ADD_JOURNAL, DELETE_JOURNAL, EDIT_JOURNAL, FETCH_JOURNAL, JournalActionTypes } from "./types";

const initialState: Journal[] = []

const updateJournal = (journals: Journal[], journalToUpdate: Journal) => {
    const accountIndex = _.findIndex(journals, {id:journalToUpdate.id})
    journals.splice(accountIndex, 1, journalToUpdate)
    return journals
}

const removeJournal = (journals: Journal[], id: string) => {
    return _.remove(journals, (e) => e.id !== id)
}

export function journalReducer(state = initialState, action: JournalActionTypes): Journal[] {
    switch (action.type) {
        case FETCH_JOURNAL: {
            return action.payload
        }
        case EDIT_JOURNAL: {
            return updateJournal([...state], action.payload)
        }
        case ADD_JOURNAL: {
            return [...state, action.payload]
        }
        case DELETE_JOURNAL: {
            return removeJournal([...state], action.payload.id)
        }
        default:
            return state
    }
}