import React, { useState, useEffect, Suspense } from 'react';
import ThemeContext from "../../context/ThemeContext";
import 'typeface-comfortaa';
import { MuiThemeProvider as ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { lightTheme, darkTheme } from './themes';
import './index.css';
import Layout from "./Layout";
import { BrowserRouter } from "react-router-dom";
import { hideNotification } from "../../store/app/actions";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store";
import Routes from './Routes';
import SnackbarNotification from "./Notifications/SnackbarNotification";
import ServiceWorker from "./ServiceWorker";

const mapStateToProps = (state: RootState) => ({
    notification: state.app.notification,
    theme: 'dark',
})
const mapDispatchToProps = {
    hideNotification
}
const connector = connect(mapStateToProps, mapDispatchToProps)
type AppProps = ConnectedProps<typeof connector>

const App = (props: AppProps) => {
    const [theme, setTheme] = useState(props.theme)
    const changeTheme = (changeTo: string): void => setTheme(changeTo)
    const themeContextValue = { theme, changeTheme }
    
    useEffect(() => {
        setTheme(props.theme)
    }, [props.theme])

    return (
        <ThemeProvider theme={createMuiTheme(theme === 'dark' ? darkTheme : lightTheme)}>
            <ThemeContext.Provider value={themeContextValue}>
                <CssBaseline />
                <BrowserRouter>
                    <Layout>
                    <Suspense fallback={<div>Loading...</div>}>
                        <Routes />
                    </Suspense>
                    </Layout>
                    <SnackbarNotification
                        isError={props.notification.isError}
                        message={props.notification.message}
                        show={props.notification.show}
                        close={props.hideNotification}
                    />
                    <ServiceWorker/>
                </BrowserRouter>
            </ThemeContext.Provider>
        </ThemeProvider>
    )
}

export default connector(App)