import axios from 'axios';
import {getAuthToken, clearAuthToken, clearUserProfile} from "../utils/userUtils";

if (!process.env.REACT_APP_API_BASE_URL) {
    throw new Error("Please set REACT_APP_API_BASE_URL environment variable")
}

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL.trim()
});

apiClient.interceptors.request.use(request => {
    // Auth token
    const authToken = getAuthToken();
    if (authToken && authToken.trim()) {
        request.headers.token = authToken.trim()
    }

    return request;
});

apiClient.interceptors.response.use((response) => {
    return response
}, function (error) {
    if ((error.response) && (error.response.status) && (error.response.status === 401)) {
        clearAuthToken()
        clearUserProfile()
        window.location.href = "/"
    }
    return Promise.reject(error.response ? error.response : error);
});

export default apiClient