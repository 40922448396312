import { batch } from "react-redux";
import { LOGIN, LOGOUT } from "./types";
import { signInWithGoogle, signOut } from "../../services/userService";
import { AppThunk } from "../index";
import { hidePageLoader, showNotification, showPageLoader } from "../app/actions";
import { clearAuthToken, clearUserProfile, setAuthToken, setUserProfile } from "../../utils/userUtils";

export const loginInWithGoogle = (idToken: string): AppThunk => async dispatch => {
    try {
        dispatch(showPageLoader())

        const response = await signInWithGoogle(idToken)

        setAuthToken(response.data.data.token)
        setUserProfile(response.data.data.user)

        batch(() => {
            dispatch({
                type: LOGIN,
                payload: response.data.data
            })
            dispatch(hidePageLoader())
        })
    } catch (e) {
        console.log(e)
        batch(() => {
            dispatch(hidePageLoader())
            dispatch(showNotification("Unable to sign in"))
        })
    }
}

export const logoutFromServer = (): AppThunk => async dispatch => {
    try {
        dispatch(showPageLoader())

        await signOut()

        clearAuthToken()
        clearUserProfile()

        batch(() => {
            dispatch({
                type: LOGOUT
            })
            dispatch(hidePageLoader())
        })
    } catch (e) {
        batch(() => {
            dispatch(hidePageLoader())
            dispatch(showNotification("Unable to sign out"))
        })
    }
}