import _ from "lodash";
import { JournalEntry } from "../../components/JournalEntry/JornalEntry.model";
import { ADD_JOURNAL_ENTRY, DELETE_JOURNAL_ENTRY, EDIT_JOURNAL_ENTRY, FETCH_JOURNAL_ENTRY, JournalEntryActionTypes } from "./types";

const initialState: JournalEntry[] = []

const updateJournalEntry = (journalEntries: JournalEntry[], journalEntryToUpdate: JournalEntry) => {
    const journalEntryIndex = _.findIndex(journalEntries, {id:journalEntryToUpdate.id})
    journalEntries.splice(journalEntryIndex, 1, journalEntryToUpdate)
    return journalEntries
}

const removeJournalEntry = (journalEntries: JournalEntry[], id: string) => {
    return _.remove(journalEntries, (e) => e.id !== id)
}

export function journalEntryReducer(state = initialState, action: JournalEntryActionTypes): JournalEntry[] {
    switch (action.type) {
        case FETCH_JOURNAL_ENTRY: {
            return action.payload
        }
        case EDIT_JOURNAL_ENTRY: {
            return updateJournalEntry([...state], action.payload)
        }
        case ADD_JOURNAL_ENTRY: {
            return [...state, action.payload]
        }
        case DELETE_JOURNAL_ENTRY: {
            return removeJournalEntry([...state], action.payload.id)
        }
        default:
            return state
    }
}