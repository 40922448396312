import { appReducer } from "./app/reducers";
import { userReducer } from "./user/reducers";
import { Action, combineReducers } from "redux";
import { ThunkAction } from "redux-thunk";
import { journalReducer } from "./journal/reducers";
import { journalEntryReducer } from "./journalEntry/reducers";

export const rootReducer = combineReducers({
    app: appReducer,
    user: userReducer,
    journals: journalReducer,
    journalEntries: journalEntryReducer
})

export type RootState = ReturnType<typeof rootReducer>

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
    RootState,
    unknown,
    Action<string>>