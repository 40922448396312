import {User} from "../components/Profile/User";

export const isLoggedIn = (): boolean => {
    const token: string | null = getAuthToken()

    return Boolean(token && token.trim())
}

export const getUserProfile = (): User => {
    const user: string | null = localStorage.getItem("user")
    if (user && user.trim()) {
        return JSON.parse(user)
    }

    return {
        name: '', email: '', imageUrl: undefined
    }
}

export const getAuthToken = (): string | null => {
    return localStorage.getItem("auth_token")
}

export const setAuthToken = (token: string): void => {
    localStorage.setItem("auth_token", token)
}

export const setUserProfile = (profile: User): void => {
    localStorage.setItem("user", JSON.stringify(profile));
}

export const clearAuthToken = (): void => {
    localStorage.removeItem("auth_token")
}

export const clearUserProfile = (): void => {
    localStorage.removeItem("user")
}