import React, {lazy} from 'react';
import {Switch} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

const Login = lazy(() => import("../../Login"));
const Journal = lazy(() => import("../../Journal"));
const Profile = lazy(() => import("../../Profile"));

const Routes = () => {
    return(
        <Switch>
            <PublicRoute restrictedForSignedInUser exact path={'/'} component={Login}/>
            <PrivateRoute exact path={'/journals'} component={Journal}/>
            <PrivateRoute exact path={'/profile'} component={Profile}/>
        </Switch>
    )
}

export default Routes